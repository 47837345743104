@mixin svgBg($svg, $color) {
	background-color: $color;
	-webkit-mask-image: url($svg);
	mask-image: url($svg);
	-webbit-mask-size: cover;
	mask-size: cover;
}

// Media queries

@mixin media($breakpoint) {
	@media (min-width: $breakpoint) {
		@content;
	}
}

@mixin media-sm {
	@include media($breakpoint-sm) {
		@content;
	}
}

@mixin media-md {
	@include media($breakpoint-md) {
		@content;
	}
}

@mixin media-lg {
	@include media($breakpoint-lg) {
		@content;
	}
}

@mixin media-xl {
	@include media($breakpoint-xl) {
		@content;
	}
}

@mixin media-xxl {
	@include media($breakpoint-xxl) {
		@content;
	}
}

@mixin matIconButtonSize($selector, $buttonSize, $iconSize) {
	#{$selector} {
		width: $buttonSize !important;
		height: $buttonSize !important;
		padding: 0 !important;
		display: inline-flex !important;
		align-items: center;
		justify-content: center;

		& > *[role=img] {
			width: $iconSize;
			height: $iconSize;
			font-size: $iconSize;

			svg {
				width: $iconSize;
				height: $iconSize;
			}
		}

		.mat-mdc-button-touch-target {
			width: $buttonSize !important;
			height: $buttonSize !important;
		}
	}
}

// Typography

@mixin title1() {
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.0225rem;
}

@mixin title2() {
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.02rem;
}

@mixin title3() {
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.015rem;
}

@mixin subtitle1() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.0175rem;
}

@mixin subtitle2() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.015rem;
}

@mixin body1() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.0175rem;
}

@mixin body1Accent() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.0175rem;
}

@mixin body2() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.015rem;
}

@mixin label() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.015rem;
}

@mixin caption() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.6875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.015rem;
}

@mixin comment() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.75rem;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
}

@mixin overline() {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.03125rem;
	text-transform: uppercase;
}

@mixin truncate() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin button1() {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 19.07px;
}

@mixin button2() {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 19.07px;
}
