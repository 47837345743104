@import "colors";
$table-header-bg: #FAFAFA;
$table-header-color: $color-grey-500;

$table-border: 1px solid $color-blue-100;

$table-row-bg: $color-white;
$table-row-height: 45px;
$table-row-border: 1px solid $color-grey-300;

$table-row-hover-bg: $color-blue-300;
$table-row-hover-color: none;

$table-parent-row-bg: $color-blue-100;

$paginator-text-color: $color-grey-500;
$paginator-arrow-size: 30px;
$paginator-arrow-color: $color-grey-500;
