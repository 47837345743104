@import "src/assets/styles/variables/variables";

.cdk-overlay-backdrop {
	background-color: $backdrop-bg;
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container.mat-mdc-dialog-container {
	padding: 0;
	height: auto;
	position: relative;

	.mat-mdc-dialog-surface {
		overflow-y: inherit;
		border-radius: 0.5rem;
		background-color: transparent;
	}

	.mat-mdc-dialog-title {
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
		background: $color-blue-700;
		color: $color-white;
		padding: 0.5rem 1rem;
		border-bottom: $app-border;
		display: flex;
		align-items: center;
		height: $header-footer-height;
		margin: 0;

		h1 {
			margin: 0;
			color: $color-white;
			display: inline-block;
		}

		*:not([class^="icon-"]):not([class*=" icon-"]) {
			flex-grow: 1;
		}

		button {
			background-color: transparent;
			border: none;
			color: $app-border-color;
		}
	}

	.mat-mdc-dialog-content {
		padding: 24px;
		overflow: auto;
		max-height: calc(100% - (#{$header-footer-height} * 2));
		margin: 0;
		background-color: $color-white;

		&.no-padding {
			padding: 0;
		}
	}

	.service-header {
		&.mat-mdc-dialog-content {
			padding-bottom: 0;

			.as-separator {
				display: block;
				margin: 10px 30px 0 30px;
				border-top: 1px solid $color-grey-300;
			}
		}
	}

	// style for each Additional Service
	.service-content {
		overflow-y: auto;
		height: 500px;

		&.mat-mdc-dialog-content {
			padding: 0 50px 20px 50px;
			margin: 0 4px 20px 4px;
			position: relative;

			.as-part-block, [as-part-block] {
				width: 100%;
				padding: 20px 0;
			}

			.as-part-title, [as-part-title] {
				color: $color-blue-700;
				font-size: 16px;
				font-weight: bold;
			}

			.as-field-label, [as-field-label] {
				color: $color-grey-500;
				font-weight: bold;
				font-size: 13px;
			}

			.as-separator, [as-separator] {
				display: block;
				margin: 0 0;
				border-top: 1px solid $color-grey-300;
			}
		}
	}

	.footer-border {
		.mat-mdc-dialog-actions {
			border-top: 2px solid $color-grey-300;
		}
	}

	.mat-mdc-dialog-actions {
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		background-color: $color-white;
		display: flex;
		align-items: end;
		justify-content: center;
		gap: 15px;
		margin: 0;
		padding: 0.75rem 1.5rem 1.5rem;
		box-sizing: border-box;
	}

	*::-webkit-scrollbar {
		width: 7px;
	}

	*::-webkit-scrollbar-track {
		background-color: rgba($color-blue-300, 0.5);
		border-radius: 10px;
		border: 1px solid $color-blue-300;
		margin: 2px;
	}

	*::-webkit-scrollbar-thumb {
		background-color: $color-grey-500;
		border-radius: 10px;
	}

}
