@import "src/assets/styles/variables/variables";


.mat-mdc-card {
  width: 100%;
  height: 100%;
}
app-color-card {
  [card-title] {
    padding: 10px 15px 0 15px ;
    font-weight: 500;
  }
  [card-bloc] {


    &.content {
      padding: 10px 15px;
    }

    &:not(:first-of-type) {
      border-top: 4px solid $color-white;
    }

    &.address {
      font-weight: 500;
      color: $color-black;
    }
  }
}

app-expand-content {

  &.fullwidth {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }

	  .mat-expansion-panel-header {
		  padding: 0 5px;
	  }
  }

  .mat-expansion-panel {
    background: transparent;
    box-shadow: none !important;
  }

  .mat-expansion-panel-header:hover {
    background-color: $color-grey-100 !important;
  }

  &.display-block-title {
    .mat-expansion-panel-header-title {
      display: block;
    }
  }
  

  [title] {
    font-weight: bold;
  }

  .big {
    &.mat-expansion-panel {
      .mat-expansion-panel-header {
        font-size: 1.25rem;
        font-weight: 600;
        color: $color-blue-700;
      }

      .mat-expansion-indicator {
        margin-left: 20px;

        &:after {
          height: 15px;
          width: 15px;
          color: $color-blue-700;
        }
      }

      [info] {
        padding-right: 30px;
      }
    }
  }

  .medium {
    &.mat-expansion-panel {

      .mat-expansion-panel-header {
        font-size: 1.25rem;
        font-weight: 550;
        color: $color-blue-700;

      }

      .mat-expansion-indicator {
        margin-left: 0;

        &:after {
          height: 13px;
          width: 13px;
          color: $color-blue-700;
        }
      }

      [info] {
        span ~ span {
          padding-left: 10px;
        }
      }
    }
  }

  .small {

    &.mat-expansion-panel {

      .mat-expansion-panel-header {
        font-size: 0.85rem;
        font-weight: 500;
        color: $color-blue-700;
      }


      .mat-expansion-indicator {
        margin-left: 0;

        &:after {
          height: 10px;
          width: 10px;
          color: $color-blue-700;
        }
      }

      [info] {
        padding-right: 30px;
      }
    }
  }
}
