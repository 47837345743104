
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "src/assets/styles/variables/colors";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admcs-sirius-client-primary: mat.define-palette($mat-app);
$admcs-sirius-client-accent: mat.define-palette($mat-app-accent);

// The warn palette is optional (defaults to red).
$admcs-sirius-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admcs-sirius-client-theme: mat.define-light-theme((
color: (
primary: $admcs-sirius-client-primary,
accent: $admcs-sirius-client-accent,
warn: $admcs-sirius-client-warn,
)
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admcs-sirius-client-theme);

/* You can add global styles to this file, and also import other style files */

* {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

:root {
	//colors
	--color-black: #{$color-black};
	--color-white: #{$color-white};
	--color-primary-100: #{$color-primary-100};
	--color-primary-400: #{$color-primary-400};
	--color-primary-800: #{$color-primary-800};
	--color-secondary-100: #{$color-secondary-100};
	--color-secondary-200: #{$color-secondary-200};
	--color-grey-100: #{$color-grey-100};
	--color-grey-200: #{$color-grey-200};
	--color-grey-300: #{$color-grey-300};
	--color-grey-400: #{$color-grey-400};
	--color-grey-500: #{$color-grey-500};
	--color-grey-700: #{$color-grey-700};
	--color-blue-100: #{$color-blue-100};
	--color-blue-200: #{$color-blue-200};
	--color-blue-300: #{$color-blue-300};
	--color-blue-700: #{$color-blue-700};
	--color-accent-red: #{$color-accent-red};
	--color-accent-red-light: #{$color-accent-red-light};
	--color-accent-important: #{$color-accent-important};
	--color-accent-yellow: #{$color-accent-yellow};
	--color-accent-yellow-light: #{$color-accent-yellow-light};
	--primary-gradient: #{$primary-gradient};
	--primary-gradient-2: #{$primary-gradient-2};
	--strength-low: #{$strength-low};
	--strength-medium: #{$strength-medium};
	--strength-strong: #{$strength-strong};
}

html, body { height: 100%; }
body { margin: 0;}

@import "assets/styles/template/template";
@import "assets/font/admcs/style";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "leaflet/dist/leaflet.css";
@import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

.mat-drawer-container {
    background-color: white;
}

.mat-drawer {
    background-color: #fafafa;
}

.mapLoadingActualRoute {
    background-color: $color-white;
	padding: 2px 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	.loader {
		margin-left: 5px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: relative;
		animation: rotate 1s linear infinite
	}
	.loader::before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		inset: 0px;
		border-radius: 50%;
		border: 3px solid $color-primary-400;
		animation: prixClipFix 2s linear infinite ;
	}

	@keyframes rotate {
		100%   {transform: rotate(360deg)}
	}

	@keyframes prixClipFix {
		0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
		25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
		50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
		75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
		100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
	}
}
