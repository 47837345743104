@import "src/assets/styles/variables/variables";
body {
    table.mat-mdc-table {
        width: 100%;
        background: none;
        &.empty-table {
          height: 300px;
        }



        &.clickable {
            tr {
                cursor: pointer;
            }
        }

        thead {
          background: $table-header-bg;

          th {
            color: $table-header-color;
            font-weight: bold;
          }

          mat-sort-header-container {
            justify-content: space-between;
          }

          .mat-sort-header-arrow {
            margin: 0 6px 0 0;
            color: $table-header-color;
          }
        }

      tbody {
        background: $table-row-bg;
        font-weight: 500;
      }

      tr td {
        transition: $background-transition;
      }

      &.hoverable {
        tr:hover {
          &:not(.sub-element) {
            td {
              cursor: pointer;
              color: $table-row-hover-color;
              background: $table-row-hover-bg;
            }
          }
        }
      }


      tr.mat-mdc-header-row {
        box-shadow: 0 3px 2px $color-shadow-light;
        height: $table-row-height;
      }

      tr.mat-mdc-row, tr.mat-mdc-footer-row {
        height: $table-row-height;
      }

      mat-row, mat-header-row, mat-footer-row, th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
        border-bottom: $table-row-border;
      }

      &.bordered-header thead th {
        border-top: $table-border;
        border-bottom: $table-border;
      }


        tr.parent-element {
          td {
            background: $table-parent-row-bg;
          }
        }


      &.no-border {
        .mat-mdc-cell, .mat-mdc-header-cell {
          border: none !important;
        }
      }
    }

    .mat-mdc-paginator {
      background: $table-header-bg;
      border-top: $table-border;
      border-bottom: $table-border;
      color: $paginator-text-color;
      /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow {
        color: $paginator-text-color;
      }

      .mat-mdc-paginator-container {
        min-height: $table-row-height;
            color: $paginator-text-color;
            font-weight: 500;

            .mat-mdc-form-field {
                margin: 0;
                font-weight: bold;
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-wrapper {
                    padding: 0;
                }
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-infix {
                    border: none;
                }
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-underline {
                    display: none;
                }
            }

            .mat-mdc-icon-button {
                width: $paginator-arrow-size;
                height: $paginator-arrow-size;
                line-height: $paginator-arrow-size;

                &:not(:disabled) {
                    color: $paginator-arrow-color;
                }
            }
        }
    }
}

.table-responsive-2 {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
        padding: 0 10px;
        white-space: nowrap;
        vertical-align: middle;
    }
    //overflow: auto;
}


