
.scrollable-y {
  overflow-y: scroll;
  &.hide-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
