@import "src/assets/styles/variables/variables";

.mat-mdc-tab-group {
	// Couleur de la barre sous la tab active sans hover
	--mdc-tab-indicator-active-indicator-color: #{$color-primary-400};
	// Couleur au hover de la tab active
	--mat-tab-header-active-ripple-color: #{$color-blue-700};
	// Couleur de la barre sous la tab active avec hover
	--mat-tab-header-active-hover-indicator-color: #{$color-primary-400};
	// // Couleur au hover d'une tab inactive
	--mat-tab-header-inactive-ripple-color: #{$color-blue-700};
	// Couleur de la barre sous la tab active au click dessus
	--mat-tab-header-active-focus-indicator-color: #{$color-primary-400};
	height: 100%;

	.mat-mdc-tab-body-wrapper {
		height: 100%
	}

	.mat-mdc-tab-header {
		border-bottom: $color-grey-300 1px solid;

		.mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
			.large {
				min-height: 68px;
				min-width: 100px;
				max-width: 125px;

				&:hover {
					.mdc-tab__content {
						.mdc-tab__text-label {
							.content__tab__title {
								color: $color-blue-700;
							}
						}
					}

				}


				.mdc-tab__content {
					height: 100%;

					.mdc-tab__text-label {
						height: 100%;
					}
				}
			}
		}
	}	

	.mat-mdc-tab-body-content {
		scrollbar-width: thin;
		scrollbar-color: $color-grey-300 transparent;

		&::-webkit-scrollbar {
			width: 0.5rem;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-grey-300;
			border-radius: 3.75rem;
		}
	}
}
