@import "src/assets/styles/variables/variables";

.sirius-marker-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	font-family: Montserrat, sans-serif;
	font-weight: 700;
	font-size: 1.25rem;
	color: $color-blue-700;
	background: $color-primary-400;

	&.done {
		border: 1px solid $color-grey-300;
		background-color: $color-grey-500;
	}

	&.planned {
		background: $color-secondary-100;
		border: 1px solid $color-secondary-200;
	}

	&.in-progress {
		background: $color-primary-400;
		border: 1px solid $color-primary-400;
	}

	&.incident::after {
		content: '';
		background: $color-accent-red;
		width: 0.625rem;
		height: 0.625rem;
		border-radius: 50%;
		position: absolute;
		left: 70%;
		bottom: 70%;
		transform: translate(-10%, 10%);
	}
}

.sirius-map-line {
	color: $color-primary-400;
}

.cluster {
	display: flex;
	flex-direction: column;
	width: 100% !important;
	height: 100% !important;

	.sirius-position-marker {
		width: 100% !important;
		height: 100% !important;
		display: flex;
    	flex-direction: column;

		&.incident::after {
			content: '';
			background: $color-accent-red;
			width: 0.625rem;
			height: 0.625rem;
			border-radius: 50%;
			position: absolute;
			left: 10px;
			bottom: 10px;
			transform: translate(-10%, 10%);
		}
	}

	.sirius-current-position-marker {
		> .content {
			width: 35px;
			height: 35px;
		}
	}
}

.cluster-tooltip {
	background-color: red;
}

.leaflet-tooltip-top:has(.clearTooltip):before {
	border: none !important;
}
.leaflet-tooltip-bottom:has(.clearTooltip):before {
	border: none !important;
}
.leaflet-tooltip-left:has(.clearTooltip):before {
	border: none !important;
}
.leaflet-tooltip-right:has(.clearTooltip):before {
	border: none !important;
}

.orderGroup {
	position: absolute;
	top: 17px;
	left: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
    width: 100%;
    align-items: center;

	.order {
		background-color: #C0F2F9;
		border: 1px #00BBD4 solid;
		padding: 0 4px 0 4px;
		color: #080F36;
		width: 24px;
		border-radius: 5px;
		line-height: 12px;
		font-weight: bold;
		display: flex;
		align-items: center;
	}

	img {
		height: 19px;
	}

	&.onTask {
		top: 22px;
		left: 0px;
	}
}

.sirius-position-marker {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	.content {
		img {
			filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(412%) hue-rotate(88deg) brightness(113%) contrast(100%);
		}
	}

}

.sirius-current-position-marker {
	background: $primary-gradient;
	padding: 0.125rem;

	&.incident::after {
		content: '';
		background: $color-accent-red;
		width: 0.625rem;
		height: 0.625rem;
		border-radius: 50%;
		
		left: 70%;
		bottom: 70%;
		transform: translate(-10%, 10%);
	}

	> .content {
		background-color: $color-blue-700;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.task-number {
		@include title3();
		min-width: 1.25rem;
		
		left: 50%;
		top: 100%;
		transform: translate(-50%, -50%);
		background-color: $color-primary-100;
		border: $color-primary-400 1px solid;
		border-radius: 50%;
		text-align: center;
	}
}

.sirius-tracking-marker {
	background: $color-grey-500;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
}

.sirius-incident-marker {
	background-color: $color-accent-red-light;
	border: 1px solid $color-accent-red;
	transition: background-color 300ms ease, border-color 300ms ease;

	&:hover {
		background-color: $color-grey-300;
		border-color: $color-grey-400;
	}
}

.sirius-tooltip {
	@include caption();
	background-color: #FFFFFF00;
	color: $color-white;
	border: none;
	border-radius: 0.125rem;
	padding: 0;
	box-shadow: none;

	&::before {
		display: none;
	}

	.task {
		background-color: #080F36;
		width: 150px;
		text-wrap: wrap;
		margin: 2px;
		padding: 3px;
		border-radius: 0.125rem;
		text-align: center;
	}

	.incident {
		background-color: #D20202;
		width: 150px;
		text-wrap: wrap;
		margin: 2px;
		padding: 3px;
		border-radius: 0.125rem;
		text-align: center;
	}

	.current {
		background-color: #080F36;
		width: 150px;
		text-wrap: wrap;
		margin: 2px;
		padding: 3px;
		border-radius: 0.125rem;
		text-align: center;
	}
}

.leaflet-legend-control {
	background-color: $color-white;
	padding: 0.25rem;
	font-size: 1rem;

	.legend-item {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.legend-color {
			width: 1rem;
			height: 1rem;
		}
	}
}
