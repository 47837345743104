@import "src/assets/styles/variables/variables";

.changeable {
  padding: 15px;

  span {
    &[class^="icon-"], &[class*=" icon-"] {
      visibility: hidden;
    }
  }

  &:hover {
    border-radius: 5px;
    background: $color-grey-300;

    span {
      &[class^="icon-"], &[class*=" icon-"] {
        visibility: visible;
      }
    }
  }
}

.mat-mdc-form-field {
	&.ng-invalid.ng-touched {
		span[matPrefix] {
			background: $color-accent-red;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
  &.mat-focused {
    span {
      &[matPrefix] {
		  background: $color-primary-400;
		  -webkit-background-clip: text;
		  -webkit-text-fill-color: transparent;
      }
    }
  }
}
