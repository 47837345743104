@import "src/assets/styles/variables/variables";
h1,
h2,
h3,
h4 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	display: block;
}

h1 {
	color: $color-blue-700;
	font-size: 20px;
	font-weight: 800;
}

h2 {
	color: $color-blue-700;
	font-size: 16px;
	font-weight: 800;
}

h3 {
	color: $color-blue-700;
	font-size: 14px;
	font-weight: 600;
}

a {
	color: $color-primary-400;
	text-decoration: none;
}

a:hover {
	color: $color-primary-400;
	cursor: pointer;
}

.body-1 {
	font-size: 14px;
	font-weight: 400;
}

.body-1-accent {
	font-size: 14px;
	font-weight: 600;
}

.body-2 {
	font-size: 12px;
	font-weight: 400;
}

.subtitle-1 {
	font-size: 14px;
	font-weight: 700;
}

.caption {
	font-size: 11px;
	font-weight: 600;
}

.web-label {
    font-size: 12px;
    font-weight: 700;
}

span,
div {
	&.hover {
		&:hover {
			cursor: pointer;
			background: $primary-gradient-2;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}

.more-counter {
	@include label;
	height: 1.5rem;
	width: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: $color-white;
	color: $color-blue-700;
}
