@import "src/assets/styles/variables/variables";

.cdk-overlay-backdrop {
	//background-color: unset !important;
}

.mat-mdc-menu-content {
	width: fit-content;
	height: 100%;
	padding: 10px !important;

	.title {
		font-size: 12px;
		color: $color-white;
		font-weight: bold;
	}

	.icon {
		color: red;
	}

	.mat-mdc-menu-item {
		padding: 0;
		color: $color-white;
		font-size: 18px;

		img {
			margin-right: 0.5rem;
		}

		/*    &:hover {
			  background-color: green;
			}*/
	}
}

.white {
	.mat-mdc-menu-content {
		background-color: $color-white;
		width: fit-content;

		.title {
			color: $color-black;
		}

		.mat-mdc-menu-item {
			color: $color-black;
		}
	}
}

.mat-mdc-menu-panel {
	margin-top: 14px;
}

.mat-mdc-menu-panel.notif-menu {
	background: $color-blue-700;
	max-height: 400px;
	width: 440px;
	max-width: 440px;
	overflow: hidden;


	.mat-mdc-menu-content {
		background-color: $color-blue-700;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}

#my-account-sidenav .mat-drawer-inner-container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 1.25rem;
	min-height: 0;
}

.account-menu {
	--mat-menu-container-color: #{$color-blue-700};
}
