@import "./src/assets/styles/variables/variables";

body {
	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	.mat-mdc-form-field-suffix {
		ngx-mat-timepicker-toggle {
			button {
				display: contents;
			}
		}
	}

	::placeholder {
		color: rgba(0, 0, 0, 0.18) !important;
	}

	.mat-mdc-form-field:not(.no-bg) {
		.mat-mdc-text-field-wrapper {
			.mat-mdc-form-field-flex {
				.mdc-notched-outline {
					background: $color-white;
					z-index: -1;
				}
			}
		}
	}

	.mat-mdc-select-panel {
		.mat-mdc-option.mat-mdc-option-active,
		.mdc-list-item.mdc-list-item--selected:not(.mdc-list-item--disabled) {
			span.mdc-list-item__primary-text {
				color: $color-blue-700;
				font-weight: 600;
			}

			.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
				background-color: $color-blue-700;
			}
		}
	}

	.mat-mdc-list-item.multi-line {
		.mat-mdc-list-item-unscoped-content {
			white-space: initial;
		}
	}

	.mat-mdc-form-field.mat-mdc-paginator-page-size-select.mat-focused {
		.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
			color: $color-blue-700;
		}
	}

	.mat-mdc-form-field {
		&.no-bg {
			div[class^="mdc-notched-outline"] {
				border: none;
			}
		}

		& .mat-mdc-form-field-subscript-wrapper {
			min-height: 0;
		}
	}

	/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
	.mat-autocomplete-panel {
		&.wide {
			.mat-mdc-option {
				line-height: 0.9;
				font-size: 0.75rem;
				height: 75px;
			}
		}
	}

	.mat-mdc-checkbox label,
	.mat-mdc-radio-button label,
	mat-label,
	.label-element {
		color: $color-grey-500;
		font-weight: bold;
	}

	.mat-mdc-checkbox label,
	.mat-mdc-radio-button label {
		cursor: pointer;
	}

	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	.mat-mdc-checkbox-inner-container {
		width: 1.2rem;
		height: 1.2rem;
		/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
		.mat-mdc-checkbox-frame {
			background: $color-white;
			border-color: $color-grey-300;
		}
	}

	.mat-mdc-checkbox.mat-accent {
		--mdc-checkbox-selected-checkmark-color: white;

		&.ng-invalid.ng-touched {
			--mdc-checkbox-unselected-icon-color: var(--mdc-theme-error, #f44336);
			--mdc-checkbox-unselected-pressed-icon-color: var(--mdc-theme-error, #f44336);
		}
	}

	//input[type=number],
	//input[type=number] {
	//  &.arrows {
	//    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
	//      opacity: 1;
	//    }
	//  }
	//}

	@media (min-width: 1200px) {
		.mat-mdc-radio-button ~ .mat-radio-button {
			margin-left: 16px;
		}
	}

	.mat-mdc-radio-button {
		label {
			@include label();
			color: $color-grey-500;
		}
		&.mat-mdc-radio-checked {
			label {
				color: $color-primary-400;
			}
		}
	}

	app-button {
		[class^="icon-"],
		[class*=" icon-"] {
			&:not(:only-child) {
				padding-right: 5px;
			}
		}
	}

	.mat-mdc-form-field {
		//width: 250px;
		max-width: 100%;
	}

	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	.mat-mdc-form-field-wrapper {
		padding-bottom: 0;
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-mdc-form-field-underline {
			position: initial !important;
			display: block;
			margin-top: -1px;
		}

		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-mdc-form-field-ripple {
			position: initial !important;
			display: table;
		}
	}

	.mat-mdc-form-field-subscript-wrapper {
		padding: 0 !important;
		min-height: calc(1em + 1px);
		font-size: 0.7rem;

		.mat-mdc-form-field-error-wrapper {
			padding: 0;
		}

		.mat-mdc-form-field-hint-wrapper {
			padding: 0;

			.mat-mdc-form-field-hint {
				display: flex;
				flex: 0 0 100%;
			}
		}
	}

	.mat-form-field-appearance-standard {
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-mdc-form-field-underline {
			height: 0;
		}
	}

	.textarea-form-field {
		.mat-mdc-text-field-wrapper {
			min-height: 5em !important;
			max-height: 17em !important;
		}
	}

	.mat-form-field-appearance-outline {
		margin-top: 0.25rem;

		// &.mat-mdc-form-field-has-icon-suffix {
		// 	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		// 		padding-top: 12px;
		// 		padding-bottom: 13px;
		// 	}

		// 	.mat-mdc-text-field-wrapper {
		// 		max-height: 44px;
		// 	}
		// }

		.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
			padding-top: 9px;
			padding-bottom: 9px;
		}

		.mat-mdc-text-field-wrapper {
			max-height: 40px;
		}

		.mat-mdc-form-field-infix {
			min-height: 36px;
		}

		.mdc-notched-outline {
			&__leading {
				border-radius: 2.5px 0 0 2.5px !important;
				min-width: 2.5px !important;
			}

			&__trailing {
				border-radius: 0 2.5px 2.5px 0 !important;
			}
		}

		.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline {
			&__leading,
			&__notched,
			&__trailing {
				border-color: $color-grey-300;
			}
		}

		.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid).mdc-text-field--focused .mdc-notched-outline {
			&__leading,
			&__notched,
			&__trailing {
				border-color: $color-primary-400;
			}
		}

		.mat-mdc-form-field-infix {
			padding: 0 0 8px;
			line-height: 1.4;
		}

		.mat-mdc-form-field-icon-prefix,
		.mat-mdc-form-field-icon-suffix {
			top: 0;
			color: $color-grey-500;
			// font-size: 1.2rem;
			font-size: 1rem;

			.small {
				font-size: 0.8rem;
			}

			.mdc-icon-button {
				width: 40px;
				height: 40px;
				padding: 8px;
			}
		}

		.mat-mdc-form-field-icon-prefix {
			padding-right: 5px;
			padding: 10px;
		}

		.mat-mdc-form-field-icon-suffix {
			padding-left: 5px;
			align-self: center;
		}

		.wide {
			&.mat-mdc-select-panel {
				.mat-mdc-option {
					line-height: 0.9;
					font-size: 0.75rem;
					height: 75px;
				}
			}
		}
	}

	.mat-form-field-appearance-outline.ng-valid:not(.ng-pristine) {
		.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid, .mdc-text-field--focused) .mdc-notched-outline {
			&__leading,
			&__notched,
			&__trailing {
				border-color: $color-grey-500;
			}
		}
	}

	.no-border-input {
		border: none;

		&:focus {
			outline: none;
		}
	}

	// Special case

	.no-space-under {
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-mdc-form-field-wrapper {
			height: auto;
			padding: 0;
			margin: 0;
		}

		.mat-mdc-form-field-subscript-wrapper {
			display: none;
		}
	}

	.status-label {
		display: flex;
		text-align: center;
		align-items: center;

		app-dot-color {
			margin-right: 10px;
		}
	}

	.filter-tooltip {
		white-space: pre-line;
	}

	.panel-filter-mobile {
		.status-label {
			width: 100%;
			display: flex;
			justify-content: center;
			text-align: center;
			position: relative;

			app-dot-color {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
		/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
		.mat-select-value {
			/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
			.mat-select-value-text {
				width: 100%;
			}
		}

		/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
		.mat-select-trigger {
		}

		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-mdc-form-field-outline {
			background-color: $color-blue-100;
		}

		/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
		.mat-select-value {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-grey-500;
			font-weight: bold;
		}

		&.mat-mdc-select-panel {
			.mat-mdc-option {
				color: $color-grey-500;
				font-weight: bold;

				&:hover {
					background-color: $color-blue-100;
				}

				&.mat-active {
					background-color: $color-blue-300;
					color: $color-blue-700;
				}
			}
		}
	}

	.load-more {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
	}

	.mat-input_small {
		width: 128px;
	}

	.mat-input_medium {
		width: 200px;
	}

	.mat-input_large {
		width: 280px;
	}

	.tel-input {
		background: $color-white;
		border: 1px solid $color-grey-300;
		border-radius: 2.5px;
		outline: none;
		height: 40px;

		&:focus,
		&:focus-visible {
			border-color: $color-primary-400;
			border-width: 2px;
		}

		// &.error {
		// 	border-color: $color-red;
		// 	border-width: 2px;
		// }
	}

	ngx-intl-tel-input.ng-invalid.ng-touched {
		.tel-input {
			border-color: $color-accent-red;
		}
	}

	ngx-intl-tel-input.ng-touched {
		.tel-input.invalid {
			border-color: $color-accent-red;
		}
	}

	.iti:has(.tel-input) {
		margin-top: 0.25rem;
		margin-bottom: calc(1em + 1px + 0.67em);

		.iti__flag-container {
			height: 40px;
		}
	}

	.no-style-button{
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;
	}
}
