$header-size: 3.125rem;
$header-border-size: 0.175rem;
$footer-size: 3rem;
$shipment-nav-width: 80px;
$shipment-nav-width-expanded: 200px;
$content-height: calc(100vh - #{$header-size} - #{$footer-size});
$height-without-header: calc(100% - #{$header-size});
$height-without-footer: calc(100% - $footer-size);
$content-margin: 35px;
$spinner-size: 120px;

$background-transition: background 200ms;

// Small devices (landscape phones, 576px and up)
$screen-sm: 576px;
// Medium devices (tablets, 768px and up)
$screen-md: 768px;
// Large devices (desktops, 992px and up)
$screen-lg: 992px;
// Extra large devices (large desktops, 1200px and up)
$screen-xl: 1200px;

$picture-border-size: 2px;
$picture-dot-size: 24px;
$picture-medium-size: 36px;
$picture-big-size: 80px;
$picture-large-size: 210px;
$big-border-size: 4px;

.required::after {
    content: ' *';
    color: $color-primary-400;
}
$input-selector-height: 40px;

$openSans: 'Open Sans', Arial, Helvetica, sans-serif;
$montserrat: 'Montserrat', Arial, Helvetica, sans-serif;
