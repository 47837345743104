body {
	.mdc-checkbox {
		.mdc-checkbox__background {
			width: 20px;
			height: 20px;
			border-radius: 3px;
		}
	}

	.mat-mdc-checkbox {
		.mdc-checkbox:not(:hover) {
			.mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
				opacity: 0;
			}
		}
	}
}
