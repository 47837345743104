.mat-mdc-paginator {

	.mat-mdc-paginator-outer-container {
		flex-direction: row-reverse;
		.mat-mdc-paginator-container {
			flex-direction: row-reverse;
			justify-content: flex-start;
			.mat-mdc-paginator-page-size {
				margin-left: auto;
				flex-direction: row-reverse;
				display: flex;
				justify-content: flex-end;

				.mdc-notched-outline > div {
					border: none;
				}
			}
			.mat-mdc-paginator-range-actions {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				.mat-mdc-paginator-range-label {
					margin-right: auto;
				}
				.paginator-current-page {
					width: 30px;
					text-align: center;
				}


				.mat-mdc-tooltip-trigger {
					position: relative;

					&.mat-mdc-paginator-navigation-first, &.mat-mdc-paginator-navigation-last {
						margin: 0 5px;
					}

					&.mat-mdc-paginator-navigation-previous {
						display: none;
					}

					&.mat-mdc-paginator-navigation-next {
						display: none;
					}

					.mat-mdc-paginator-icon {
						position: absolute;
						top: 5px;
						left: 2px;
					}
				}

			}
		}
	}
}
