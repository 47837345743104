@import "src/assets/styles/variables/variables";

.mat-mdc-select-panel {
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-track {
		background-color: rgba($color-blue-300, 0.5);
		border-radius: 10px;
		border: 1px solid $color-blue-300;
		margin: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-grey-500;
		border-radius: 10px;
	}

	.mat-mdc-option {
		height: auto;

		&.action {
			.mdc-list-item__primary-text {
				@include body1Accent();
				color: $color-primary-400 !important;
			}
		}
		.mdc-list-item__primary-text {
			color: $color-grey-500;
			@include label();
		}

		.mat-pseudo-checkbox {
			width: 1rem;
			height: 1rem;
		}

		.mat-pseudo-checkbox-checked {
			background-color: $color-primary-400 !important;
		}
	}

}
