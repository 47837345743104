@import "./src/assets/styles/variables/variables";

.mat-mdc-slide-toggle {

	&.spaced-label {
		.mdc-form-field {
			.mdc-label {
				margin-right: 150px;
				text-wrap: normal;
			}
		}
	}

	.mdc-form-field {
		.mdc-label {
			@include label();
			color: $color-grey-500;
			margin: 0 0.5rem;
		}
	}

	.mdc-switch {
		background: $color-grey-500;
		width: 44px;
		height: 21px;
		border-radius: 11px;

		&__ripple {
			display: none;
		}

		.mdc-switch__track::before {
			background-color: $color-grey-500 !important;
		}
		&--checked:not(.mdc-switch--disabled) {
			background-color: $color-secondary-200 !important;
			.mdc-switch__track::after {
				background-color: $color-secondary-200 !important;
			}
		}

		.mdc-switch__handle-track {
			.mdc-switch__handle {
				width: 17px;
				height: 17px;
				margin-left: 2px;
				&::after  {
					background: $color-white !important;
				}

				.mdc-switch__icons {
					background: transparent;
					position: relative;

					.mdc-switch__icon {
						position: absolute;
						top: 23px;
						right: -23px;
						transform: scale(2);
						&--on > path {
							d: path($check-path);
							fill: $color-grey-500,
						}

						&--off {
							right: -28px;
							& > path {
								d: path($cross-path);
								fill: $color-grey-500,
							}
						}
					}


				}
			}
		}
	}




	/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
	.mat-slide-toggle-thumb {
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 19px;
		width: 19px;
		margin-top: 4px;
		background: $color-white;

		&:before {
			font-size: 11px;
			font-family: 'admcs', serif !important;
			content: "\e92b";
		}
	}

	&.mat-checked {
		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
		.mat-slide-toggle-bar {
			background: $color-secondary-200;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
		.mat-slide-toggle-thumb {
			background: $color-white;

			&:before {
				content: "\e929";
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
		.mat-slide-toggle-thumb-container {
			transform: translate3d(23px, 0, 0) !important;
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
	.mat-slide-toggle-thumb-container {
		transform: translate3d(1px, 0, 0) !important;
	}


	&.spaced-label {
		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
		.mat-slide-toggle-content {
			margin-right: 150px;
			text-wrap: normal;
		}
	}

	@media (max-width: 1200px) {
		&.spaced-label {
			/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
			.mat-slide-toggle-content {
				white-space: pre-wrap;
				overflow: visible;
				margin-right: 0;
			}
		}
	}
}
