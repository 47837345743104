// Base
$color-black: black;
$color-white: #ffffff;

// Primary
$color-primary-100: #c0f2f9;
$color-primary-400: #00bbd4;
$color-primary-800: #20a1b2;

// Secondary
$color-secondary-100: #e3ffdb;
$color-secondary-200: #a2ee8b;

// Grey
$color-grey-100: #fafafa;
$color-grey-200: #f3f4f7;
$color-grey-300: #dfe1e6;
$color-grey-400: #a9b2c0;
$color-grey-500: #6b778c;
$color-grey-700: #1B1D21;

// Blue
$color-blue-100: #f9faff;
$color-blue-200: #f2f5fc;
$color-blue-300: #e7ecfa;
$color-blue-700: #080f36;

// Accent
$color-accent-red: #ed0808;
$color-accent-red-light: #ffe3e3;
$color-accent-important: #fff4f4;
$color-accent-yellow: #ffd600;
$color-accent-yellow-light: #fff8d2;

// Gradient
$primary-gradient: linear-gradient(to right, $color-secondary-200 0%, $color-primary-400 45%);
$primary-gradient-2: linear-gradient(to bottom right, $color-secondary-200 0%, $color-primary-400 45%);

$color-link-hover: rgba(8, 15, 54, 0.15);
$color-link-active: rgba(0, 187, 212, 0.30);

$strength-low: #f44336;
$strength-medium: #f48f36;
$strength-strong: #4caf50;

$check-path: 'M2.82598 4.98515L1.01265 3.17181L0.395508 3.78134L2.82598 6.21181L8.04351 0.994289L7.43398 0.384766L2.82598 4.98515Z';
$cross-path: 'M5.84811 0.693423L5.26602 0.111328L2.95897 2.41838L0.651919 0.111328L0.0698242 0.693423L2.37687 3.00047L0.0698242 5.30752L0.651919 5.88961L2.95897 3.58257L5.26602 5.88961L5.84811 5.30752L3.54106 3.00047L5.84811 0.693423Z';

$color-shadow: #080f3626;
$color-shadow-light: #0000000f;

// Material design palette for Angular Material
// http://mcg.mbitson.com/#!?primary=%23005db9&warn=%23bc003a&themename=digisco
$mat-app-accent: (
    50: #ebf7fa,
    100: #cdecf3,
    200: #ace0ec,
    300: #8ad3e4,
    400: #71c9de,
    500: #58c0d8,
    600: #50bad4,
    700: #47b2ce,
    800: #3daac8,
    900: #2d9cbf,
    A100: #fff,
    A200: #cef3ff,
    A400: #9be6ff,
    A700: #81e0ff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);
$mat-app: (
    50: #f4fdf1,
    100: #e3fadc,
    200: #d1f7c5,
    300: #bef3ae,
    400: #b0f19c,
    500: #a2ee8b,
    600: #9aec83,
    700: #90e978,
    800: #86e76e,
    900: #75e25b,
    A100: #ffffff,
    A200: #ffffff,
    A400: #e8ffe3,
    A700: #d3ffc9,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #fff,
        A400: #fff,
        A700: #fff,
    ),
);

$app-bg: $color-blue-100;
$app-border-size: 1px;
$app-border-color: $color-grey-300;
$app-border: $app-border-size solid $app-border-color;
