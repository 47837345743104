@import "src/assets/styles/variables/variables";
.button-container {
  mat-icon {
    color: $color-white !important;
  }
  span {
    &.icon {
      font-size: 1rem;
    }
  }
}
